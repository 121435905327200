<template>
  <el-dialog :visible.sync="isShow" custom-class="custom-dialog" :modal-append-to-body="false">
    <div class="custom-dialog-head" slot="title">
      <div class="header__title">
        <h1>{{ $t("反水比例") }}</h1>
      </div>
    </div>
    <div class="news__body" v-html="$t('反水比例介绍')"></div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow:false
    }
  },
  methods:{
    show(){
      this.isShow = true
    }
  }
}
</script>

<style scoped lang="less"></style>